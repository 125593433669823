import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        setCurrentUser(user);
        setLoading(false);
    }, []);

    function signup(email, password) {
        const user = { email, isAdmin: false };
        localStorage.setItem('currentUser', JSON.stringify(user));
        setCurrentUser(user);
    }

    function login(email, password) {
        // In a real app, you'd check the password here
        const user = { email, isAdmin: email === 'admin@example.com' };
        localStorage.setItem('currentUser', JSON.stringify(user));
        setCurrentUser(user);
    }

    function logout() {
        localStorage.removeItem('currentUser');
        setCurrentUser(null);
    }

    const value = {
        currentUser,
        signup,
        login,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}