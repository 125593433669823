import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';

const RulesAndGuidelines = () => {
    return (
        <div className="min-h-screen bg-gray-100">
            <Navigation />
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-8">Rules and Guidelines</h1>

                <section className="mb-12">
                    <h2 className="text-2xl font-semibold mb-4">General Rules</h2>
                    <ul className="list-disc pl-5 space-y-2">
                        <li>All hunters and guests are required to have current South Carolina state hunting license along with the required game tags.</li>
                        <li>No dog hunting for deer is allowed on properties. Using a dog for tracking wounded/dead deer should be outside normal hunting hours.</li>
                        <li>Participants will abide by all rules and regulations required by Black Creek Stands.</li>
                        <li>No cutting down or damaging trees. Only minimum limb trimming for shooting lanes is acceptable.</li>
                        <li>All vehicles must remain on designated parking areas. ATV's/UTV's can be used to access stands and retrieve downed animals.</li>
                        <li>Vehicle traffic on property will be extremely limited during hunting seasons.</li>
                        <li>No vehicles allowed to travel on property during hunting hours except for deer retrieval or emergencies.</li>
                        <li>Park vehicles where they won't affect another hunter's hunt or be visible from any stand.</li>
                        <li>No joy riding during hunting seasons.</li>
                        <li>No trucks, cars or SUVs should be on any roads when wet after rain or flooding.</li>
                        <li>Respect and be courteous to other hunters on the property.</li>
                        <li>Keep areas around camps and common areas clean and orderly.</li>
                    </ul>
                </section>

                <section className="mb-12">
                    <h2 className="text-2xl font-semibold mb-4">Stand Rentals</h2>
                    <p className="mb-4">Black Creek Stands offers affordable, unguided hunting opportunities. We provide the stand and secure hunting area; you bring your hunting gear and enthusiasm.</p>
                    <h3 className="text-xl font-semibold mb-2">Rates</h3>
                    <ul className="list-disc pl-5 space-y-2">
                        <li>Full Day Hunts: $150 per day</li>
                        <li>Half Day Hunts: $75 per day</li>
                        <li>Hunting times: ½ hour before daylight until 11 am, and 2 pm – ½ hour after dusk</li>
                    </ul>
                    <h3 className="text-xl font-semibold mt-4 mb-2">What's Included</h3>
                    <ul className="list-disc pl-5 space-y-2">
                        <li>Permanent, insulated stand</li>
                        <li>Heater supplied (with additional propane)</li>
                        <li>Corn on the ground / feeder</li>
                    </ul>
                    <h3 className="text-xl font-semibold mt-4 mb-2">Requirements</h3>
                    <ul className="list-disc pl-5 space-y-2">
                        <li>Valid Driver's license</li>
                        <li>Valid SC Hunting License</li>
                        <li>Valid SC Harvest Tags</li>
                        <li>Signed Liability Waiver</li>
                        <li>Signed Harvest agreement</li>
                        <li>Signed Rental agreement</li>
                    </ul>
                </section>

                <section className="mb-12">
                    <h2 className="text-2xl font-semibold mb-4">Harvest Guidelines</h2>
                    <p className="mb-4">We are a Quality Deer Management Organization.</p>
                    <ul className="list-disc pl-5 space-y-2">
                        <li>Bucks Harvest: Minimum of 4 points on a side and 12 inch inside spread. One buck per hunt (2 per day).</li>
                        <li>Doe Harvest: Mature does, not accompanied by spotted fawns. If possible, 75lb and over in weight. One doe per hunt (2 per day).</li>
                        <li>Piebald and Albino deer are legal to harvest without meeting any restrictions.</li>
                        <li>A fine of $100 will be imposed for any bucks harvested not meeting minimum requirements.</li>
                        <li>A child's first buck can be of any size and will not incur a fine.</li>
                        <li>Failure to disclose the harvest of a small buck will result in expulsion and fines.</li>
                        <li>Pictures of ALL harvests must be emailed to blackcreekstands@gmail.com within 24 hours.</li>
                        <li>Black Creek Stands has the right to post pictures of guest harvests/trophies on social media.</li>
                        <li>Hunters may harvest any wild hog / Boar they observe in their rented stand. There are no restrictions.</li>
                    </ul>
                </section>

            </div>
        </div>
    );
};

export default RulesAndGuidelines;