import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Navigation from './Navigation';

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="border-b border-gray-200 py-4">
            <button
                className="flex justify-between items-center w-full text-left"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="text-lg font-medium">{question}</span>
                {isOpen ? <ChevronUp /> : <ChevronDown />}
            </button>
            {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
        </div>
    );
};

const FAQPage = () => {
    const faqs = [
        {
            question: "What is Black Creek Stands?",
            answer: "Black Creek Stands provides an affordable unguided hunting opportunity to people that want to hunt but may not have the time to do all the off-season prep work, the resources, land, or don't want to pay the high cost of joining a hunting club or going to an expensive out-fitter to pursue this great and rewarding pastime. Think of us as the Airbnb of hunting; tell us how many stands you need, book the date, pay, and go hunt!"
        },
        {
            question: "Where are you located?",
            answer: "Currently we have 4 deer hunting tracts in Chesterfield County, South Carolina. Over 400 acres to hunt. We have 1 tract in Anson County, North Carolina for hog hunting."
        },
        {
            question: "When is Black Creek Stands open for hunting Season?",
            answer: "Hog hunting runs from the first of January until July 1st. Whitetail Deer hunting runs from September 1st until January 1st. SC Youth hunt in the weekend after the season ends."
        },
        {
            question: "Do you allow ground or portable stand hunting?",
            answer: "No, if you hunt with us you have to utilize our permanent stands."
        },
        {
            question: "Can you bow hunt from your stands?",
            answer: "Our stands are setup for shotgun, rifle, pistol, or crossbow only."
        },
        {
            question: "What are hunters responsible for if they book with Black Creek Stands?",
            answer: "Hunters are responsible for proper hunting licensing and game tags, processing, transport, and/or disposal of all harvested animals, and providing their own firearms, crossbows, ATVs, and all hunting equipment (except for the hunting Stands, feeders, and land tracts). Black Creek Stands can provide some game retrieval, game skinning, and game storage for an additional fee on a case-by-case basis."
        },
        {
            question: "Are there game processors near Black Creek Stands hunting tracts?",
            answer: "Yes, and we can supply you with our recommended processors."
        },
        {
            question: "Is lodging available?",
            answer: "Black Creek Stands does have limited lodging based on availability. We have a bunk house, with kitchen, showers, skinning area, meat cooler. Black Creek Stands lodging if available can be used for an additional charge. Black Creek Stands does not provide meals. We can also provide information on lodging in and around the area that may accommodate your needs."
        }
    ];

    return (
        <>
            <Navigation />
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <FAQItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </div>
                <div className="mt-8">
                    <p>For any additional questions or information please email us at <a href="mailto:blackcreekstands@gmail.com" className="text-red-600 hover:underline">blackcreekstands@gmail.com</a> or DM us on our Facebook page: Black Creek Stands</p>
                </div>
            </div>
        </>
    );
};

export default FAQPage;