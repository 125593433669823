import React from 'react';
import { Link } from 'react-router-dom';

const AboutPage = () => {
    return (
        <div className="min-h-screen bg-gray-100">
            <header className="bg-white shadow-sm">
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <Link to="/" className="text-2xl font-bold text-gray-800">BlackCreek Stands</Link>
                    <nav>
                        <Link to="/" className="text-gray-600 hover:text-gray-800 ml-4">Home</Link>
                    </nav>
                </div>
            </header>
            <main className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-6">About BlackCreek Stands</h1>
                <p className="mb-4">BlackCreek Stands is your premier destination for booking private hunting stands and campsites. We connect outdoor enthusiasts with landowners to provide unique and exclusive hunting experiences.</p>
                <p className="mb-4">Our mission is to make hunting more accessible while promoting sustainable land use and wildlife conservation. We carefully vet all our locations to ensure they meet our high standards for safety, quality, and environmental stewardship.</p>
                <p className="mb-4">Whether you're a seasoned hunter or new to the sport, BlackCreek Stands offers a wide range of options to suit your needs and preferences. From elevated blinds to ground setups, we have the perfect stand for your next hunting adventure.</p>
            </main>
        </div>
    );
};

export default AboutPage;