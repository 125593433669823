import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { MapPin, ChevronLeft, ChevronRight, Image as ImageIcon } from 'lucide-react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useData } from './contexts/DataContext';
import Navigation from './Navigation';

// Fix for default marker icon
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const ImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loadedImages, setLoadedImages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const loadImages = async () => {
            const loadedImgs = await Promise.all(
                images.map((src) =>
                    new Promise((resolve) => {
                        const img = new Image();
                        img.src = src;
                        img.onload = () => resolve(src);
                        img.onerror = () => resolve(null);
                    })
                )
            );
            setLoadedImages(loadedImgs.filter(Boolean));
            setLoading(false);
        };
        loadImages();
    }, [images]);

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? loadedImages.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goToNext = () => {
        const isLastSlide = currentIndex === loadedImages.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    if (loading) {
        return (
            <div className="w-full h-96 bg-gray-200 flex items-center justify-center">
                <p className="text-gray-600">Loading images...</p>
            </div>
        );
    }

    if (loadedImages.length === 0) {
        return (
            <div className="w-full h-96 bg-gray-200 flex items-center justify-center">
                <ImageIcon size={48} className="text-gray-400" />
                <p className="ml-2 text-gray-600">No images available</p>
            </div>
        );
    }

    return (
        <div className="relative w-full h-96">
            <img src={loadedImages[currentIndex]} alt={`Stand view ${currentIndex + 1}`} className="w-full h-full object-cover rounded-lg" />
            {loadedImages.length > 1 && (
                <>
                    <button onClick={goToPrevious} className="absolute top-1/2 left-4 -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2">
                        <ChevronLeft size={24} />
                    </button>
                    <button onClick={goToNext} className="absolute top-1/2 right-4 -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2">
                        <ChevronRight size={24} />
                    </button>
                </>
            )}
        </div>
    );
};

const StandDetailPage = () => {
    const { id } = useParams();
    const { getStandById } = useData();
    const [stand, setStand] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchStand = async () => {
            try {
                setLoading(true);
                const standData = getStandById(id);
                if (standData) {
                    setStand(standData);
                } else {
                    setError('Stand not found');
                    setTimeout(() => navigate('/'), 3000);
                }
            } catch (err) {
                console.error('Error fetching stand:', err);
                setError('An error occurred while fetching the stand data');
            } finally {
                setLoading(false);
            }
        };

        fetchStand();
    }, [id, getStandById, navigate]);

    if (loading) return <div className="container mx-auto px-4 py-8">Loading...</div>;
    if (error) return <div className="container mx-auto px-4 py-8 text-red-500">{error}</div>;
    if (!stand) return null;

    return (
        <>
            <Navigation />
            <div className="container mx-auto px-4 py-8">
                <Link to="/" className="text-blue-500 hover:underline mb-4 inline-block">&larr; Back to all stands</Link>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    <div className="space-y-8">
                        <div className="bg-white rounded-lg shadow-md overflow-hidden">
                            <ImageCarousel images={stand.images} />
                        </div>
                        <div className="bg-white rounded-lg shadow-md overflow-hidden p-4">
                            <h2 className="text-xl font-semibold mb-4">Location</h2>
                            <div className="h-64 w-full">
                                <MapContainer center={[stand.gps.lat, stand.gps.lng]} zoom={13} style={{ height: '100%', width: '100%' }}>
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    />
                                    <Marker position={[stand.gps.lat, stand.gps.lng]}>
                                        <Popup>{stand.title}</Popup>
                                    </Marker>
                                </MapContainer>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
                            <h1 className="text-3xl font-bold mb-4">{stand.title}</h1>
                            <p className="flex items-center text-gray-600 mb-2">
                                <MapPin size={18} className="mr-2" /> {stand.location}
                            </p>
                            <p className="text-xl font-semibold mb-4">${stand.price} / night</p>
                            <p className="mb-6">{stand.description}</p>
                            <h2 className="text-xl font-semibold mb-2">Directions</h2>
                            <p className="mb-6">{stand.directions}</p>
                            <h2 className="text-xl font-semibold mb-2">Amenities</h2>
                            <ul className="list-disc list-inside mb-6">
                                {stand.amenities.map((amenity, index) => (
                                    <li key={index}>{amenity}</li>
                                ))}
                            </ul>
                            <div>
                                <h2 className="text-xl font-semibold mb-2">Stand Details</h2>
                                <ul className="list-disc list-inside">
                                    <li>Type: {stand.type}</li>
                                    <li>Maximum Hunters: {stand.maxHunters}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-6 rounded-lg">
                            <h2 className="text-xl font-semibold mb-4">Interested in this stand?</h2>
                            <p className="mb-4">
                                Email us at <a href="mailto:blackcreekstands@gmail.com" className="text-blue-500 hover:underline">blackcreekstands@gmail.com</a>
                            </p>
                            <p className="mb-4">Please include the following information in your email:</p>
                            <ul className="list-disc list-inside mb-4">
                                <li>Stand name: {stand.title}</li>
                                <li>Desired dates</li>
                                <li>Number of hunters</li>
                                <li>Your name</li>
                                <li>Any questions or special requests</li>
                            </ul>
                            <p className="text-sm text-gray-600">
                                We'll get back to you as soon as possible with availability and booking information.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StandDetailPage;