import React, { createContext, useContext, useState, useEffect } from 'react';

const DataContext = createContext();

const realStands = [
    // Existing stands
    {
        id: '1',
        title: 'Back 80 Stand 1',
        location: 'Gate 1: 1600 Fairview Church Rd, Mount Croghan, SC',
        price: 150,
        images: ['/images/stand1_1.jpg', '/images/stand1_2.jpg', '/images/stand1_3.jpg', '/images/stand1_4.jpg', '/images/stand1_5.jpg', "/images/stand1_6.jpg", "/images/stand1_7.jpg", "/images/stand1_8.jpg", "/images/stand1_9.jpg", "/images/stand1_10.jpg" ],
        description: 'First Green Permanent Deer Stand from Gate 1. The stand is marked with the number 1. Enter from the back of the stand. Features 3 windows that can be propped open with wood blocks.',
        type: 'Permanent Green Stand',
        maxHunters: 2,
        amenities: ['3 windows', 'Wood window blocks', 'Elevated position', 'Deer feeder'],
        rating: 95,
        reviews: 20,
        reservations: [],
        directions: 'Once you pull in past the sign, you can park on either side in the power line right away. Walk to Gate 1, use the provided combination. The stand is visible from the gate.',
        gps: { lat: 34.67866667, lng: -80.25733333 }
    },
    {
        id: '2',
        title: 'Back 80 Stand 2',
        location: 'Gate 1: 1600 Fairview Church Rd, Mount Croghan, SC',
        price: 150,
        images: ['/images/stand2_1.jpg', '/images/stand2_2.jpg', '/images/stand2_3.jpg', '/images/stand2_4.jpg', '/images/stand2_5.jpg'],
        description: 'Second Green Permanent Deer Stand from Gate 1. The stand is marked with the number 2. Enter from the back of the stand. Features 3 windows that can be propped open with wood blocks.',
        type: 'Permanent Green Stand',
        maxHunters: 2,
        amenities: ['3 windows', 'Wood window blocks', 'Elevated position', 'Deer feeder'],
        rating: 94,
        reviews: 18,
        reservations: [],
        directions: 'Access through Gate 1. This is the second stand youll encounter after entering.',
        gps: { lat: 34.67866667, lng: -80.25733333 }
    },
    {
        id: '3',
        title: 'Back 80 Stand 3',
        location: 'Gate 2: Gilmore Rd entrance',
        price: 150,
        images: ['/images/stand3_1.jpg', '/images/stand3_2.jpg', '/images/stand3_3.jpg', 'images/stand3_4.jpg', '/images/stand3_5.jpg'],
        description: 'First Green Permanent Deer Stand from Gate 2. The stand is marked with the number 3. Enter from the back of the stand. Features 3 windows that can be propped open with wood blocks.',
        type: 'Permanent Green Stand',
        maxHunters: 2,
        amenities: ['3 windows', 'Wood window blocks', 'Elevated position', 'Deer feeder'],
        rating: 96,
        reviews: 22,
        reservations: [],
        directions: 'GPS: 34°40\'43.2"N 80°15\'26.4"W. Park inside Gate 2 and lock it behind you. Stay on the cutout right away to reach the stand.',
        gps: { lat: 34.67866667, lng: -80.25733333 }
    },
    {
        id: '4',
        title: 'Back 80 Stand 4',
        location: 'Gate 3: Gibb Jordan Rd entrance',
        price: 150,
        images: ['/images/stand4_1.jpg', '/images/stand4_2.jpg', '/images/stand4_3.jpg', '/images/stand4_4.jpg', '/images/stand4_5.jpg'],
        description: 'Located near a deer feeder in a cutout area. The stand is marked with the number 4. Enter from the back of the stand. Features 3 windows that can be propped open with wood blocks.',
        type: 'Permanent Green Stand',
        maxHunters: 2,
        amenities: ['3 windows', 'Wood window blocks', 'Elevated position', 'Deer feeder'],
        rating: 97,
        reviews: 25,
        reservations: [],
        directions: 'GPS: 34.681704,-80.257926. Park inside Gate 3 and lock it behind you. Follow the cut out right away. You will come to a deer feeder in a cutout area. Turn left to find the stand.',
        gps: { lat: 34.681704, lng: -80.257926 }
    },
    // New stands at Gate 8
    {
        id: '5',
        title: 'JC Lear Stand 1',
        location: 'Gate 7: Gilmore Rd, Mt Croghan SC 29727',
        price: 150,
        images: ['/images/gate8_stand1_1.jpg', '/images/gate8_stand1_2.jpg', '/images/gate8_stand1_3.jpg'],
        description: 'Green permanent deer stand with 3 windows that can be propped open with wood blocks. Located on the left trail from the Old Barn.',
        type: 'Permanent Green Stand',
        maxHunters: 2,
        amenities: ['3 windows', 'Wood window blocks', 'Elevated position', 'Deer feeder'],
        rating: 0,
        reviews: 0,
        reservations: [],
        directions: 'Enter Gate 7 (Black Creek Stands Sign on the Gate). Park inside the gate, about ¼ mile in beside the Old Barn. From the Old Barn, take the Hunting Trail to the left. Follow this trail straight to Stand 1. The stand is marked with the number 1.',
        gps: { lat: 34.677750, lng: -80.265250 }
    },
    {
        id: '6',
        title: 'JC Lear Stand 2',
        location: 'Gate 7: Gilmore Rd, Mt Croghan SC 29727',
        price: 150,
        images: ['/images/gate8_stand2_1.jpg', '/images/gate8_stand2_2.jpg', '/images/gate8_stand2_3.jpg', '/images/gate8_stand2_4.jpg'],
        description: 'Green permanent deer stand with 3 windows that can be propped open with wood blocks. Located on the right trail from the Old Barn, at the left fork.',
        type: 'Permanent Green Stand',
        maxHunters: 2,
        amenities: ['3 windows', 'Wood window blocks', 'Elevated position', 'Deer feeder'],
        rating: 0,
        reviews: 0,
        reservations: [],
        directions: 'Enter Gate 7. Park inside the gate, about ¼ mile in beside the Old Barn. From the Old Barn, take the main Hunting Trail to the right. At the fork, take the left trail to reach Stand 2. The stand is marked with the number 2.',
        gps: { lat: 34.677750, lng: -80.265250 }
    },
    {
        id: '7',
        title: 'JC Lear Stand 3',
        location: 'Gate 7: Gilmore Rd, Mt Croghan SC 29727',
        price: 150,
        images: ['/images/gate8_stand3_1.jpg', '/images/gate8_stand3_2.jpg', '/images/gate8_stand3_3.jpg'],
        description: 'Wood-sided deer stand with 3 windows. Located on the right trail from the Old Barn, at the right fork.',
        type: 'Permanent Wood Stand',
        maxHunters: 2,
        amenities: ['3 windows', 'Elevated position', 'Deer feeder'],
        rating: 0,
        reviews: 0,
        reservations: [],
        directions: 'Enter Gate 7. Park inside the gate, about ¼ mile in beside the Old Barn. From the Old Barn, take the main Hunting Trail to the right. At the fork, take the right trail. Stand 3 will be on the right-hand side, marked by a sign. The stand is marked with the number 3.',
        gps: { lat: 34.677750, lng: -80.265250 }
    },
    {
        id: '8',
        title: 'JC Lear Stand 4',
        location: 'Gate 7: Gilmore Rd, Mt Croghan SC 29727',
        price: 150,
        images: ['/images/gate8_stand4_1.jpg', '/images/gate8_stand4_2.jpg', '/images/gate8_stand4_3.jpg'],
        description: 'Deer stand with 3 windows. Located at the end of the right trail from the Old Barn, past Stand 3.',
        type: 'Permanent Stand',
        maxHunters: 2,
        amenities: ['3 windows', 'Elevated position', 'Deer feeder'],
        rating: 0,
        reviews: 0,
        reservations: [],
        directions: 'Enter Gate 7. Park inside the gate, about ¼ mile in beside the Old Barn. From the Old Barn, take the main Hunting Trail to the right. At the fork, take the right trail. Continue past Stand 3. Stand 4 is the last stand on this trail, marked with the number 4.',
        gps: { lat: 34.677750, lng: -80.265250 }
    },
    {
        id: '9',
        title: 'Ruby Rental Stand 1',
        location: 'Gate 8: 7890 Hartsville-Ruby Rd, Patrick SC 29584',
        price: 150, // Assuming the same price as other stands
        images: ['/images/rgate8_stand1_1.jpg', '/images/rgate8_stand1_2.jpg', '/images/rgate8_stand1_3.jpg'], // Placeholder images
        description: 'Located at the end of a branch line trail to the right off the Main Trail Path. Green permanent deer stand with 3 windows that can be propped open with wood blocks.',
        type: 'Permanent Green Stand',
        maxHunters: 2, // Assuming the same max hunters as other stands
        amenities: ['3 windows', 'Wood window blocks', 'Elevated position', 'Truck/ATV accessible', 'Deer feeder'],
        rating: 0,
        reviews: 0,
        reservations: [],
        directions: 'Follow the Main Trail Path onto the property. You will come to a branch line trail to the right off the Main Trail Path. Stand 1 is located at the end of this branch line trail. The number 1 is marked on the Deer Stand. Enter the stand from the back of the stand.',
        gps: { lat: 34.6224722, lng: -80.1724167 }
    },
    {
        id: '10',
        title: 'Ruby Rental Stand 2',
        location: 'Gate 8: 7890 Hartsville-Ruby Rd, Patrick SC 29584',
        price: 150,
        images: ['/images/rgate8_stand2_1.jpg', '/images/rgate8_stand2_2.jpg', '/images/rgate8_stand2_3.jpg'], // Placeholder images
        description: 'Second Green Permanent Deer Stand visible from the Main Trail Path. Features 3 windows that can be propped open with wood blocks.',
        type: 'Permanent Green Stand',
        maxHunters: 2,
        amenities: ['3 windows', 'Wood window blocks', 'Elevated position', 'Truck/ATV accessible', 'Deer feeder'],
        rating: 0,
        reviews: 0,
        reservations: [],
        directions: 'Follow the Main Trail Path onto the property. Stand 2 can be seen from the Main Trail Path. It will be the second Green Permanent Deer Stand you come to. The number 2 is marked on the Deer Stand. Enter the stand from the back of the stand.',
        gps: { lat: 34.6194225, lng: -80.1716352 }
    },
    {
        id: '11',
        title: 'Ruby Rental Stand 3',
        location: 'Gate 8: 7890 Hartsville-Ruby Rd, Patrick SC 29584',
        price: 150,
        images: ['/images/rgate8_stand3_1.jpg', '/images/rgate8_stand3_2.jpg', '/images/rgate8_stand3_3.jpg'], // Placeholder images
        description: 'Last stand on the Main Trail Path. Green permanent deer stand with 3 windows that can be propped open with wood blocks.',
        type: 'Permanent Green Stand',
        maxHunters: 2,
        amenities: ['3 windows', 'Wood window blocks', 'Elevated position', 'Truck/ATV accessible', 'Deer feeder'],
        rating: 0,
        reviews: 0,
        reservations: [],
        directions: 'Follow the Main Trail Path onto the property. Stand 3 will be the last stand you come to on the Main Trail Path. You will pass Stand 2 on the Main Trail Path and the trail will dead end into Stand 3. The number 3 is marked on the Deer Stand. Enter the stand from the back of the stand.',
        gps: { lat: 34.6189656, lng: -80.1741561 }
    },
    {
        id: '12',
        title: 'Strand Rental Property Stand 3',
        location: 'Gate 5: 495 Gibb Jordan Rd, Mount Croghan, SC',
        price: 150, // Assuming the same price as other stands
        images: ['/images/strand_stand3_1.jpg', '/images/strand_stand3_2.jpg', '/images/strand_stand3_3.jpg', '/images/strand_stand3_4.jpg', '/images/strand_stand3_5.jpg', '/images/strand_stand3_6.jpg'], // Placeholder images
        description: 'First Green Permanent Deer Stand approximately 100 yards from Gate 5. Features 3 windows that can be propped open with wood blocks. Located on a 50+ acre property.',
        type: 'Permanent Green Stand',
        maxHunters: 2, // Assuming the same max hunters as other stands
        amenities: ['3 windows', 'Wood window blocks', 'Elevated position', 'Close to parking', 'Deer feeder'],
        rating: 0,
        reviews: 0,
        reservations: [],
        directions: 'Park on the right or left side of the road (ensure you are not blocking the road as there is local traffic). Alternatively, open the gate and pull your vehicle onto the property, parking directly in front of the gate. The stand is approximately 100 yards from Gate 5 and is likely visible from the gate. It will be the first Green Permanent Deer Stand you come to. The number 3 is marked on the Deer Stand. Enter the stand from the back.',
        gps: { lat: 34.6789444, lng: -80.2593333 }
    }
];

export function useData() {
    return useContext(DataContext);
}

export function DataProvider({ children }) {
    const [stands, setStands] = useState([]);
    const [cart, setCart] = useState([]);
    const [globalBlockedDates, setGlobalBlockedDates] = useState([]);

    useEffect(() => {
        setStands(realStands);
        const savedGlobalBlockedDates = JSON.parse(localStorage.getItem('globalBlockedDates')) || [];
        setGlobalBlockedDates(savedGlobalBlockedDates);
    }, []);

    function addStand(stand) {
        const newStands = [...stands, { ...stand, id: Date.now().toString(), reservations: [] }];
        setStands(newStands);
        localStorage.setItem('stands', JSON.stringify(newStands));
    }

    function updateStand(id, updatedStand) {
        const newStands = stands.map(stand =>
            stand.id === id ? { ...stand, ...updatedStand } : stand
        );
        setStands(newStands);
        localStorage.setItem('stands', JSON.stringify(newStands));
    }

    function deleteStand(id) {
        const newStands = stands.filter(stand => stand.id !== id);
        setStands(newStands);
        localStorage.setItem('stands', JSON.stringify(newStands));
    }

    function getStandById(id) {
        return stands.find(stand => stand.id === id);
    }

    function isDateBlocked(date) {
        return globalBlockedDates.some(blockedDate =>
            new Date(blockedDate).toDateString() === new Date(date).toDateString()
        );
    }

    function isStandAvailable(standId, startDate, endDate) {
        const stand = stands.find(s => s.id === standId);
        if (!stand || !stand.reservations) return true;  // If no reservations, it's available

        // Convert dates to timestamp for easier comparison
        const start = new Date(startDate).setHours(0, 0, 0, 0);
        const end = new Date(endDate).setHours(23, 59, 59, 999);

        // Check global blocked dates
        let currentDate = new Date(start);
        while (currentDate <= end) {
            if (isDateBlocked(currentDate)) {
                return false;
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return !stand.reservations.some(reservation => {
            const reservationStart = new Date(reservation.startDate).setHours(0, 0, 0, 0);
            const reservationEnd = new Date(reservation.endDate).setHours(23, 59, 59, 999);

            // Check for any overlap
            return (
                (start <= reservationEnd && end >= reservationStart)
            );
        });
    }

    function makeReservation(standId, startDate, endDate, hunterId) {
        if (!isStandAvailable(standId, startDate, endDate)) {
            throw new Error('Stand is not available for the selected dates');
        }

        const updatedStands = stands.map(stand => {
            if (stand.id === standId) {
                return {
                    ...stand,
                    reservations: [
                        ...(stand.reservations || []),
                        {
                            startDate: new Date(startDate).setHours(0, 0, 0, 0),
                            endDate: new Date(endDate).setHours(23, 59, 59, 999),
                            hunterId
                        }
                    ]
                };
            }
            return stand;
        });

        setStands(updatedStands);
        localStorage.setItem('stands', JSON.stringify(updatedStands));
    }

    function searchAvailableStands(startDate, endDate, hunters) {
        console.log("Searching for stands:", { startDate, endDate, hunters });
        const results = stands.filter(stand =>
            isStandAvailable(stand.id, startDate, endDate) &&
            stand.maxHunters >= hunters
        );
        console.log("Search results:", results);
        return results;
    }

    function getBookedDates(standId) {
        const stand = stands.find(s => s.id === standId);
        if (!stand || !stand.reservations) return [];

        const bookedDates = [];
        stand.reservations.forEach(reservation => {
            let currentDate = new Date(reservation.startDate);
            const endDate = new Date(reservation.endDate);
            while (currentDate <= endDate) {
                bookedDates.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1);
            }
        });

        // Add globally blocked dates
        globalBlockedDates.forEach(date => {
            bookedDates.push(new Date(date));
        });

        return bookedDates;
    }

    function addToCart(standId, startDate, endDate, hunters) {
        const stand = getStandById(standId);
        if (!stand) {
            throw new Error('Stand not found');
        }
        if (!isStandAvailable(standId, startDate, endDate)) {
            throw new Error('Stand is not available for the selected dates');
        }
        setCart([...cart, { standId, stand, startDate, endDate, hunters }]);
    }

    function removeFromCart(index) {
        setCart(cart.filter((_, i) => i !== index));
    }

    function clearCart() {
        setCart([]);
    }

    function getCartTotal() {
        return cart.reduce((total, item) => {
            const days = (new Date(item.endDate) - new Date(item.startDate)) / (1000 * 60 * 60 * 24) + 1;
            return total + (item.stand.price * days);
        }, 0);
    }

    function updateGlobalBlockedDates(newBlockedDates) {
        setGlobalBlockedDates(newBlockedDates);
        localStorage.setItem('globalBlockedDates', JSON.stringify(newBlockedDates));
    }

    const value = {
        stands,
        addStand,
        updateStand,
        deleteStand,
        getStandById,
        isStandAvailable,
        makeReservation,
        searchAvailableStands,
        getBookedDates,
        cart,
        addToCart,
        removeFromCart,
        clearCart,
        getCartTotal,
        globalBlockedDates,
        updateGlobalBlockedDates,
        isDateBlocked
    };

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
}