import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { useData } from './contexts/DataContext';

const AdminPanel = () => {
    const [newStand, setNewStand] = useState({
        title: '',
        location: '',
        price: '',
        amenities: '',
        type: '',
        maxHunters: '',
        checkInTime: '14:00',
        checkOutTime: '11:00',
        description: '',
        blockedDates: []
    });
    const [selectedStand, setSelectedStand] = useState(null);
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [activeTab, setActiveTab] = useState('add-stand');
    const [localGlobalBlockedDates, setLocalGlobalBlockedDates] = useState([]);
    const { currentUser } = useAuth();
    const { addStand, updateStand, deleteStand, stands, updateGlobalBlockedDates, globalBlockedDates } = useData();
    const navigate = useNavigate();

    useEffect(() => {
        if (!currentUser || currentUser.email !== 'test@gmail.com') {
            navigate('/');
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        setLocalGlobalBlockedDates(globalBlockedDates);
    }, [globalBlockedDates]);

    const handleAddStand = (e) => {
        e.preventDefault();
        addStand({
            ...newStand,
            id: Date.now().toString(),
            amenities: newStand.amenities.split(',').map(item => item.trim()),
            price: parseFloat(newStand.price),
            maxHunters: parseInt(newStand.maxHunters),
            blockedDates: []
        });
        setNewStand({
            title: '',
            location: '',
            price: '',
            amenities: '',
            type: '',
            maxHunters: '',
            checkInTime: '14:00',
            checkOutTime: '11:00',
            description: '',
            blockedDates: []
        });
    };

    const handleBlockDate = () => {
        if (selectedStand) {
            const updatedStand = {
                ...selectedStand,
                blockedDates: [...(selectedStand.blockedDates || []), date]
            };
            updateStand(selectedStand.id, updatedStand);
            setSelectedStand(updatedStand);
        }
    };

    const handleUnblockDate = (dateToUnblock) => {
        if (selectedStand) {
            const updatedStand = {
                ...selectedStand,
                blockedDates: (selectedStand.blockedDates || []).filter(d => d !== dateToUnblock)
            };
            updateStand(selectedStand.id, updatedStand);
            setSelectedStand(updatedStand);
        }
    };

    const handleGlobalBlockDate = () => {
        if (!localGlobalBlockedDates.includes(date)) {
            const updatedGlobalBlockedDates = [...localGlobalBlockedDates, date];
            setLocalGlobalBlockedDates(updatedGlobalBlockedDates);
            updateGlobalBlockedDates(updatedGlobalBlockedDates);
        }
    };

    const handleGlobalUnblockDate = (dateToUnblock) => {
        const updatedGlobalBlockedDates = localGlobalBlockedDates.filter(d => d !== dateToUnblock);
        setLocalGlobalBlockedDates(updatedGlobalBlockedDates);
        updateGlobalBlockedDates(updatedGlobalBlockedDates);
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Admin Panel</h1>

            <div className="mb-4">
                <button
                    onClick={() => setActiveTab('add-stand')}
                    className={`mr-2 p-2 ${activeTab === 'add-stand' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                    Add New Stand
                </button>
                <button
                    onClick={() => setActiveTab('manage-stands')}
                    className={`mr-2 p-2 ${activeTab === 'manage-stands' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                    Manage Stands
                </button>
                <button
                    onClick={() => setActiveTab('global-dates')}
                    className={`p-2 ${activeTab === 'global-dates' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                    Global Date Management
                </button>
            </div>

            {activeTab === 'add-stand' && (
                <div className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-xl font-bold mb-4">Add New Stand</h2>
                    <form onSubmit={handleAddStand} className="space-y-4">
                        <input
                            type="text"
                            placeholder="Stand Title"
                            value={newStand.title}
                            onChange={(e) => setNewStand({...newStand, title: e.target.value})}
                            className="w-full p-2 border rounded"
                        />
                        <input
                            type="text"
                            placeholder="Location"
                            value={newStand.location}
                            onChange={(e) => setNewStand({...newStand, location: e.target.value})}
                            className="w-full p-2 border rounded"
                        />
                        <input
                            type="number"
                            placeholder="Price"
                            value={newStand.price}
                            onChange={(e) => setNewStand({...newStand, price: e.target.value})}
                            className="w-full p-2 border rounded"
                        />
                        <input
                            type="text"
                            placeholder="Amenities (comma-separated)"
                            value={newStand.amenities}
                            onChange={(e) => setNewStand({...newStand, amenities: e.target.value})}
                            className="w-full p-2 border rounded"
                        />
                        <input
                            type="text"
                            placeholder="Type"
                            value={newStand.type}
                            onChange={(e) => setNewStand({...newStand, type: e.target.value})}
                            className="w-full p-2 border rounded"
                        />
                        <input
                            type="number"
                            placeholder="Max Hunters"
                            value={newStand.maxHunters}
                            onChange={(e) => setNewStand({...newStand, maxHunters: e.target.value})}
                            className="w-full p-2 border rounded"
                        />
                        <div className="flex space-x-4">
                            <div className="flex-1">
                                <label className="block text-sm font-medium text-gray-700">Check-in Time</label>
                                <input
                                    type="time"
                                    value={newStand.checkInTime}
                                    onChange={(e) => setNewStand({...newStand, checkInTime: e.target.value})}
                                    className="mt-1 w-full p-2 border rounded"
                                />
                            </div>
                            <div className="flex-1">
                                <label className="block text-sm font-medium text-gray-700">Check-out Time</label>
                                <input
                                    type="time"
                                    value={newStand.checkOutTime}
                                    onChange={(e) => setNewStand({...newStand, checkOutTime: e.target.value})}
                                    className="mt-1 w-full p-2 border rounded"
                                />
                            </div>
                        </div>
                        <textarea
                            placeholder="Description"
                            value={newStand.description}
                            onChange={(e) => setNewStand({...newStand, description: e.target.value})}
                            className="w-full p-2 border rounded"
                            rows="4"
                        ></textarea>
                        <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded">Add Stand</button>
                    </form>
                </div>
            )}

            {activeTab === 'manage-stands' && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <h2 className="text-xl font-bold mb-4">Existing Stands</h2>
                        {stands.map(stand => (
                            <div key={stand.id} className="bg-white p-4 rounded-lg shadow-md mb-4">
                                <h3 className="font-bold">{stand.title}</h3>
                                <p>{stand.location}</p>
                                <p>${stand.price}/night</p>
                                <p>Check-in: {stand.checkInTime}, Check-out: {stand.checkOutTime}</p>
                                <p>{stand.description}</p>
                                <button
                                    onClick={() => updateStand(stand.id, {...stand, price: parseFloat(stand.price) + 10})}
                                    className="mr-2 p-2 bg-green-500 text-white rounded"
                                >
                                    Increase Price
                                </button>
                                <button
                                    onClick={() => deleteStand(stand.id)}
                                    className="p-2 bg-red-500 text-white rounded"
                                >
                                    Delete
                                </button>
                                <button
                                    onClick={() => setSelectedStand(stand)}
                                    className="mt-2 p-2 bg-blue-500 text-white rounded"
                                >
                                    Manage Dates
                                </button>
                            </div>
                        ))}
                    </div>
                    <div>
                        <h2 className="text-xl font-bold mb-4">Manage Dates</h2>
                        {selectedStand ? (
                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <h3 className="font-bold">{selectedStand.title}</h3>
                                <input
                                    type="date"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    className="mt-2 p-2 border rounded"
                                />
                                <button
                                    onClick={handleBlockDate}
                                    className="mt-2 p-2 bg-blue-500 text-white rounded"
                                >
                                    Block Selected Date
                                </button>
                                <div className="mt-4">
                                    <h4 className="font-bold">Blocked Dates:</h4>
                                    <ul>
                                        {(selectedStand.blockedDates || []).map(blockedDate => (
                                            <li key={blockedDate} className="flex justify-between items-center">
                                                {blockedDate}
                                                <button
                                                    onClick={() => handleUnblockDate(blockedDate)}
                                                    className="ml-2 p-1 bg-red-500 text-white rounded text-sm"
                                                >
                                                    Unblock
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <p>Select a stand to manage dates</p>
                        )}
                    </div>
                </div>
            )}

            {activeTab === 'global-dates' && (
                <div className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-xl font-bold mb-4">Global Date Management</h2>
                    <div className="mb-4">
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            className="p-2 border rounded mr-2"
                        />
                        <button
                            onClick={handleGlobalBlockDate}
                            className="p-2 bg-blue-500 text-white rounded"
                        >
                            Block Date for All Stands
                        </button>
                    </div>
                    <div>
                        <h3 className="font-bold mb-2">Globally Blocked Dates:</h3>
                        <ul>
                            {localGlobalBlockedDates.map(blockedDate => (
                                <li key={blockedDate} className="flex justify-between items-center mb-2">
                                    {blockedDate}
                                    <button
                                        onClick={() => handleGlobalUnblockDate(blockedDate)}
                                        className="ml-2 p-1 bg-red-500 text-white rounded text-sm"
                                    >
                                        Unblock
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminPanel;