import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Calendar, Users, Menu, X, ChevronDown, ChevronUp, Search } from 'lucide-react';
import { useData } from './contexts/DataContext';
import { useAuth } from './contexts/AuthContext';
import StandCard from './StandCard';

// Assume we have these images imported
import logo from './images/logo.png';
import background1 from './images/background1.png';
import background2 from './images/background2.png';

const BackgroundCarousel = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [background1, background2];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="absolute inset-0 z-0">
            {images.map((img, index) => (
                <div
                    key={img}
                    className={`absolute inset-0 transition-opacity duration-1000 ${
                        index === currentImageIndex ? 'opacity-100' : 'opacity-0'
                    }`}
                    style={{
                        backgroundImage: `url(${img})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
            ))}
        </div>
    );
};

const Navigation = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
        } catch {
            console.error("Failed to log out");
        }
    };

    const isAdmin = currentUser && currentUser.email === 'test@gmail.com';

    return (
        <nav className="bg-white shadow-sm relative z-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                    <div className="flex items-center">
                        <Link to="/" className="flex-shrink-0 flex items-center">
                            <img src={logo} alt="Black Creek Stands Logo" className="h-8 w-auto sm:h-10" />
                            <span className="ml-2 text-xl font-bold text-gray-800">Black Creek Stands</span>
                        </Link>
                    </div>
                    <div className="hidden sm:ml-6 sm:flex sm:items-center">
                        <Link to="/faq" className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium">FAQ</Link>
                        <Link to="/about" className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium">About</Link>
                    </div>
                    <div className="flex items-center sm:hidden">
                        <button
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        >
                            <span className="sr-only">Open main menu</span>
                            {isMenuOpen ? (
                                <X className="block h-6 w-6" aria-hidden="true" />
                            ) : (
                                <Menu className="block h-6 w-6" aria-hidden="true" />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="border-b border-gray-200 py-4">
            <button
                className="flex justify-between items-center w-full text-left"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="text-lg font-semibold">{question}</span>
                {isOpen ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
            </button>
            {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
        </div>
    );
};


const BlackCreekStandsHomepage = () => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [hunters, setHunters] = useState('');
    const { stands, searchAvailableStands } = useData();
    const [searchResults, setSearchResults] = useState(null);
    const datePickerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setShowDatePicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDateChange = (item) => {
        setDateRange([item.selection]);
    };

    const formatDateRange = (range) => {
        const start = range[0].startDate;
        const end = range[0].endDate;
        if (start.getTime() === end.getTime()) {
            return start.toLocaleDateString();
        }
        return `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`;
    };

    const handleSearch = () => {
        const results = searchAvailableStands(
            dateRange[0].startDate,
            dateRange[0].endDate,
            parseInt(hunters) || 1
        );
        setSearchResults(results);
        console.log('Search results:', results);
    };

    const [isExpanded, setIsExpanded] = useState(false);


    const renderStands = (standsToRender, title, isSearchResult = false) => (
        <div className="container mx-auto px-4 py-8 md:py-16">
            <h2 className="text-3xl font-bold mb-8 text-center">{title}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                {(isExpanded ? standsToRender : standsToRender.slice(0, 4)).map((stand) => (
                    <div key={stand.id} className="bg-white rounded-lg shadow-md overflow-hidden relative">
                        {isSearchResult && (
                            <div className="absolute top-2 right-2 bg-green-500 text-white px-2 py-1 rounded-full text-xs flex items-center">
                                <span className="w-2 h-2 bg-white rounded-full mr-1"></span>
                                Available
                            </div>
                        )}
                        <img src={stand.images[0]} alt={stand.title} className="w-full h-48 object-cover" />
                        <div className="p-4">
                            <h3 className="text-xl font-semibold mb-2">{stand.title}</h3>
                            <p className="text-gray-600 mb-4">{stand.location}</p>
                            <ul className="text-sm text-gray-500 mb-4">
                                {stand.amenities.slice(0, 3).map((amenity, index) => (
                                    <li key={index} className="mb-1">• {amenity}</li>
                                ))}
                            </ul>
                            <Link to={`/stand/${stand.id}`} className="text-red-500 hover:text-red-600 font-semibold">
                                View Details
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
            {standsToRender.length > 4 && (
                <div className="text-center mt-8">
                    <p className="text-black-500 font-semibold mb-2">
                        {isExpanded ? "Collapse" : "View All Stands"}
                    </p>
                    <button
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="bg-white text-red-500 border border-red-500 rounded-full p-2 hover:bg-red-500 hover:text-white transition-colors duration-300"
                        aria-label={isExpanded ? "Collapse stands list" : "Expand stands list"}
                    >
                        {isExpanded ? (
                            <ChevronUp className="w-6 h-6" />
                        ) : (
                            <ChevronDown className="w-6 h-6" />
                        )}
                    </button>
                </div>
            )}
        </div>
    );

    const faqItems = [
        {
            question: "What is Black Creek Stands?",
            answer: "Black Creek Stands provides affordable unguided hunting opportunities for people who want to hunt but may not have the time, resources, or land to do so. We offer an Airbnb-like experience for hunting: book your stand, pay, and go hunt!"
        },
        {
            question: "Where are you located?",
            answer: "We currently have 4 deer hunting tracts in Chesterfield County, South Carolina, totaling over 400 acres. We also have 1 tract in Anson County, North Carolina for hog hunting."
        },
        {
            question: "When is the hunting season?",
            answer: "Hog hunting runs from January 1st to July 1st. Whitetail Deer hunting runs from September 1st to January 1st, with a SC Youth hunt on the weekend after the season ends."
        },
        {
            question: "Do you allow ground or portable stand hunting?",
            answer: "No, if you hunt with us, you must use our permanent stands."
        }
    ];

    return (
        <div className="min-h-screen flex flex-col">
            <Navigation />

            <main className="flex-grow flex flex-col">
                <section className="relative">
                    <BackgroundCarousel/>
                    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                    <div className="container mx-auto px-4 py-16 relative z-10">
                        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-8">
                            <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">Discover Your Perfect
                                Hunting Stand</h1>
                            <p className="text-xl mb-8 text-center text-gray-600">Find and reserve private hunting
                                stands tailored to your needs.</p>

{/*                            <div
                                className="flex flex-col md:flex-row md:items-end space-y-4 md:space-y-0 md:space-x-4 mb-6">
                                <div className="flex-grow" ref={datePickerRef}>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Dates</label>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            readOnly
                                            value={formatDateRange(dateRange)}
                                            onClick={() => setShowDatePicker(!showDatePicker)}
                                            className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                                        />
                                        <Calendar className="absolute right-3 top-2.5 text-gray-400" size={20}/>
                                    </div>
                                    {showDatePicker && (
                                        <div
                                            className="absolute z-10 mt-2 bg-white shadow-lg rounded-lg overflow-hidden">
                                            <DateRange
                                                editableDateInputs={true}
                                                onChange={handleDateChange}
                                                moveRangeOnFirstSelection={false}
                                                ranges={dateRange}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Hunters</label>
                                    <div className="relative">
                                        <input
                                            type="number"
                                            value={hunters}
                                            onChange={(e) => setHunters(e.target.value)}
                                            min="1"
                                            placeholder="Number of hunters"
                                            className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                                        />
                                        <Users className="absolute right-3 top-2.5 text-gray-400" size={20}/>
                                    </div>
                                </div>
                                <button
                                    onClick={handleSearch}
                                    className="bg-red-500 text-white px-6 py-2 rounded-md hover:bg-red-600 transition duration-300 ease-in-out flex items-center justify-center"
                                >
                                    <Search size={20} className="mr-2"/>
                                    Search
                                </button>
                            </div>*/}
                        </div>
                    </div>
                </section>

                {searchResults && (
                    <section className="bg-white">
                        {renderStands(searchResults, "Search Results", true)}
                    </section>
                )}

                <section className="bg-gray-100">
                    {renderStands(stands, "Our Stands", false)}
                </section>

                <section className="bg-white">
                    <div className="container mx-auto px-4 py-8 md:py-16">
                        <h2 className="text-2xl font-bold mb-6">Testimonials</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <p className="italic mb-4">"Black Creek Stands is a crew of amazing people, and great land,
                                    if you ever need a place to hunt, this is about as good as it gets."</p>
                                <p className="font-semibold">- James Buttion</p>
                            </div>
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <p className="italic mb-4">"The guys at Black Creek Stands are truly the best of the best. If you ever
                                    want to hunt, go Black Creek!"</p>
                                <p className="font-semibold">- Matt Haupt</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-white">
                    <div className="container mx-auto px-4 py-8 md:py-16">
                        <h2 className="text-2xl font-bold mb-6">Rules and Guidelines</h2>
                        <div className="grid md:grid-cols-3 gap-8">
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <h3 className="text-xl font-semibold mb-4">General Rules</h3>
                                <ul className="list-disc pl-5 space-y-2">
                                    <li>Valid hunting license and tags required</li>
                                    <li>No dog hunting for deer</li>
                                    <li>Respect other hunters and the property</li>
                                    <li>Use designated parking areas only</li>
                                </ul>
                            </div>
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <h3 className="text-xl font-semibold mb-4">Stand Rentals</h3>
                                <ul className="list-disc pl-5 space-y-2">
                                    <li>Weekdays & Weekends: $150/day</li>
                                    <li>Half day hunts:$75/day</li>
                                    <li>Includes insulated stand and heater</li>
                                    <li>Valid hunting license and signed agreements required</li>
                                </ul>
                            </div>
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <h3 className="text-xl font-semibold mb-4">Harvest Guidelines</h3>
                                <ul className="list-disc pl-5 space-y-2">
                                    <li>Bucks: 4 points on a side, 12" inside spread</li>
                                    <li>Does: Mature, not with spotted fawns</li>
                                    <li>Email harvest pictures within 24 hours</li>
                                    <li>No restrictions on wild hog harvest</li>
                                </ul>
                            </div>
                        </div>
                        <div className="mt-8 text-center">
                            <Link to="/rules-and-guidelines" className="text-red-500 hover:text-red-600 font-semibold">
                                View Full Rules and Guidelines
                            </Link>
                        </div>
                    </div>
                </section>

                <section className="bg-gray-100">
                    <div className="container mx-auto px-4 py-8 md:py-16">
                        <h2 className="text-2xl font-bold mb-6">Frequently Asked Questions</h2>
                        <div className="space-y-4">
                            {faqItems.map((item, index) => (
                                <FAQItem key={index} question={item.question} answer={item.answer}/>
                            ))}
                        </div>
                        <div className="mt-8 text-center">
                            <Link to="/faq" className="text-red-500 hover:text-red-600 font-semibold">
                                View all FAQs
                            </Link>
                        </div>
                    </div>
                </section>
            </main>

            <footer className="bg-gray-800 text-white py-8">
                <div className="container mx-auto px-4">
                    <p>&copy; 2024 Black Creek Stands. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default BlackCreekStandsHomepage;