import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Trash2 } from 'lucide-react';
import { useData } from './contexts/DataContext';

const CartPage = () => {
    const { cart, removeFromCart, clearCart, getCartTotal } = useData();
    const navigate = useNavigate();

    const handleCheckout = () => {
        // Implement checkout logic here
        alert('Proceeding to checkout...');
        // After successful checkout:
        // clearCart();
        // navigate('/confirmation');
    };

    if (cart.length === 0) {
        return (
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold mb-4">Your Cart</h1>
                <p>Your cart is empty. <Link to="/" className="text-blue-500 hover:underline">Find a stand</Link></p>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-4">Your Cart</h1>
            {cart.map((item, index) => (
                <div key={index} className="bg-white shadow-md rounded-lg p-4 mb-4 flex justify-between items-center">
                    <div>
                        <h2 className="text-xl font-semibold">{item.stand.title}</h2>
                        <p className="text-gray-600">{new Date(item.startDate).toLocaleDateString()} - {new Date(item.endDate).toLocaleDateString()}</p>
                        <p className="text-gray-600">Hunters: {item.hunters}</p>
                        <p className="font-semibold">${item.stand.price} / night</p>
                    </div>
                    <button onClick={() => removeFromCart(index)} className="text-red-500 hover:text-red-700">
                        <Trash2 size={20} />
                    </button>
                </div>
            ))}
            <div className="mt-4 text-right">
                <p className="text-xl font-bold">Total: ${getCartTotal()}</p>
                <button
                    onClick={handleCheckout}
                    className="mt-4 bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600"
                >
                    Proceed to Checkout
                </button>
            </div>
        </div>
    );
};

export default CartPage;