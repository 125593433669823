import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { DataProvider } from './contexts/DataContext';
import BlackCreekStandsHomepage from './BlackCreekStandsHomepage';
import AboutPage from './AboutPage';
import LoginPage from './LoginPage';
import SignupPage from './SignupPage';
import AdminPanel from './AdminPanel';
import StandDetailPage from './StandDetailPage';
import CartPage from "./CartPage";
import FAQPage from "./FAQPage";
import RulesAndGuidelines from "./RulesAndGuidelines";

function App() {
    return (
        <AuthProvider>
            <DataProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<BlackCreekStandsHomepage />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/signup" element={<SignupPage />} />
                        <Route path="/admin" element={<AdminPanel />} />
                        <Route path="/stand/:id" element={<StandDetailPage />} />
                        <Route path="/cart" element={<CartPage />} />
                        <Route path="/faq" element={<FAQPage />} />
                        <Route path="/rules-and-guidelines" element={<RulesAndGuidelines />} />
                    </Routes>
                </Router>
            </DataProvider>
        </AuthProvider>
    );
}

export default App;